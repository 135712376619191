
import React, { useEffect, useRef, useState } from 'react'
import { SlMenu } from 'react-icons/sl'
import { Link } from 'react-router-dom'
import { HomeNavs } from '../utils/PageUtils'
import logo from 'assets/main-images/logo.png'
import { Bounce } from 'react-awesome-reveal'
import { BackToTop } from '../utils/functions'
import Imaged from '../utils/Imaged'

const Header = () => {
    const togref = useRef()
    const [views, setViews] = useState(false)
    useEffect(() => {
        togref && window.addEventListener('click', e => { togref.current != null && !togref.current.contains(e.target) && setViews(false) }, true)
    }, [])
    return (
        <>
            <div className={`${views ? '' : 'hidden'} fixed top-0 left-0 w-full h-screen bg-black/60 z-40`}>
                <div ref={togref} className="bg-white ani">
                    <div className="flex flex-col">
                    <div className="px-4 py-3.5">
                        <Bounce> <Link onClick={BackToTop} className='' to="/"> <Imaged src={logo} alt="" className="h-7 lg:h-10" /> </Link></Bounce>
                    </div>
                        {HomeNavs.map((item, i) => (
                            <Link
                                onClick={BackToTop}
                                to={item.url} key={i} className='capitalize py-3.5 px-4 font-light hover:text-sub'> <Bounce>{item.title}</Bounce> </Link>
                        ))}
                        <Link onClick={BackToTop} to={'/login'} className='capitalize py-3.5 px-4 font-light hover:text-sub'> <Bounce>Login account</Bounce> </Link>
                        <Link onClick={BackToTop} to={'/signup'} className='capitalize py-3.5 px-4 font-light hover:text-sub'> <Bounce>Get started</Bounce> </Link>
                        
                    </div>
                </div>
            </div>


            <div className="sticky top-0 left-0 z-30 w-full p-5 shadow-2xl bg-white">
                <div className="flex items-center justify-between w-11/12 gap-10 mx-auto lg:w-10/12">
                    <div className="">
                        <Bounce> <Link onClick={BackToTop} className='' to="/"> <Imaged src={logo} alt="" className="h-7 lg:h-10" /> </Link></Bounce>
                    </div>
                    <div className="flex-row hidden lg:flex">
                        {HomeNavs.map((item, index) => (
                            <Link onClick={BackToTop} to={`${item.url}`} key={index} className='py-2.5 px-5 text-main text-base truncate'><Bounce>{item.title}</Bounce></Link>
                        ))}
                    </div>
                    <div className="flex-row hidden lg:flex">
                        <Link onClick={BackToTop} to="/login" className='px-5 py-2 text-sm bg-white rounded-lg h-fit truncate'>Account Login </Link>
                        <Link onClick={BackToTop} to="/signup" className='px-5 py-2 ml-5 text-sm text-white rounded-lg h-fit bg-sub truncate'> Get Started </Link>
                    </div>
                    <div className="lg:hidden"> <SlMenu onClick={() => setViews(!views)} className='text-3xl text-main cursor-pointer' /> </div>
                </div>
            </div>
            {/* <GeneralChatModal /> */}
        </>
    )
}

export default Header